import React, { useState, useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { FaBorderNone } from 'react-icons/fa';
import * as Survey from 'survey-react';
import 'survey-react/survey.css'

import questionnaire from '../survey/questionnaire.json'
import { API_ENDPOINT } from '../utils/endpoint';

export const SurveyComponent = () => {

	Survey.Survey.cssType = "defaultV2"

	const htmlC =  "<h5>Thank you for completing the survey! You can view the results on the  <a href='/dashboard'>Dashboard</a>" 
	// Survey.defaultBootstrapCss.navigationButton = 'btn btn-lg'
	// Survey.defaultV2Css.matrix.itemDecorator = 'invisible'
	// Survey.defaultV2Css.progressText = 'invisible'
	// Survey.defaultV2Css.matrix.cell = 'text-left'
	// Survey.defaultV2Css.progressBar = 'visible'


	const onComplete = async (survey) => {
		const result = survey.data 
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/survey/results`, {
			headers: 
			{'Content-type': 'application/json', 
			'Authorization': `Bearer ${token}`}, 
			method: 'POST',
			body: JSON.stringify(result)
		}).then((res) => res.json())
		.then(result => console.log(result))
	}

	Survey
		.StylesManager
		.applyTheme('defaultV2')

	

	const myCSS = {
		matrix: {
			root: "centerAlign table table-condensed",
			itemDecorator: 'invisible',
			headerCell: 'simplewidth text-start',
			cell: 'text-start', 
	
		},
		progressText: 'invisible',
		progressBar: 'visible',
		question: {
			mainRoot: 'pb-5 pt-5',
			title: '',
			number: '',
			titleOnError: '',
			
		},
		error: {
			aboveQuestion: 'alert alert-danger'
		},
		navigationButton: "btn btn-primary btn-lg rounded-pill",

	};

	let defaultThemeColors = Survey
		.StylesManager
		.ThemeColors["default"];
	defaultThemeColors["$main-color"] = "#21409A";
	defaultThemeColors["$main-hover-color"] = "#008bae";
	defaultThemeColors["$text-color"] = "#21409A";
	defaultThemeColors["$header-color"] = "#21409A";

	defaultThemeColors["$header-background-color"] = "#4a4a4a";
	defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

	// Survey
	// 	.StylesManager
	// 	.applyTheme()

	return (
		<Container fluid>
			<Card className = "border-0 shadow p-2 text-align-left">
				<Card.Body>
					<Survey.Survey
						json={questionnaire}
						css={myCSS}
						showCompletedPage={true}
						onComplete={survey => onComplete(survey)}
						completedHtml = {htmlC}
					/>
				</Card.Body>
			</Card>
		</Container>
	)
}