import React, { useState, useEffect } from 'react';
import { Defaultnav } from '../components/navbar';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { ReactComponent as AssessmentImg } from '../images/assessment-icon-21.jpg'
import { useHistory } from 'react-router-dom';
import { API_ENDPOINT } from '../utils/endpoint';
import { ButtonGroup } from 'react-bootstrap';
import { ButtonToolbar } from 'react-bootstrap';
export const HomePage = () => {
	const history = useHistory()

	const [status, setStatus] = useState(null)

	const getStatus = async () => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/survey/status`, {
			headers:
			{
				'Content-type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			method: 'GET',
		}).then((res) => res.json())
			.then(result => setStatus(result.status))
	}

	const permissions = localStorage.getItem('permissions')

	useEffect(() => {
		getStatus()
	}, [])

	return (
		<>
			<Defaultnav >
			</Defaultnav>
			{status &&
				<div class="p-5 mb-4 bg-light rounded-3">
					<Container fluid className="vh-100">
						<Row className="justify-content-center align-items-center">
							<Col className="md-12">
								<Card className="mx-auto border-0" style={{ background: 'rgb(221,221,221, .5)', borderRadius: '5px' }}>
									<Card.Header className="border-0 bg-transparent" >
										<h2 className="display-5 fw-bold">Leading the Culture Transformation</h2>
									</Card.Header>
									<Card.Body className="leftAligned">
										<p >To assist in the continuing transformation of One MFT's Culture, we have a created a short assessment tool. </p>

										<p>This tool is designed to help you, as a Leader in MFT, understand how well you are aligned to the 12 dimensions of the Culture Transformation. Your results will identify areas of strengths as well as opportunities for improvement. You can also use this tool with your team or direct reports to gain a better understanding of where your team is aligned to the 12 dimensions and to help focus your leadership. </p>

										<p>There are 32 total questions which should take approximately <strong>10 minutes </strong> to complete. Please answer each question as openly and honestly as possible for the most accurate profile.
										</p>

										{(status !== 'complete' && permissions !== 'admin') ?
											<Button className="btn btn-primary btn-lg rounded-pill" type="button" onClick={() => history.push('/survey')}>Begin Survey</Button> :
											<ButtonToolbar className="justify-content-center">
												<ButtonGroup className="me-2">
													<Button className="btn btn-primary btn-lg rounded-pill mr-2" type="button" onClick={() => history.push('/dashboard')}>Results Dashboard</Button>

												</ButtonGroup>

												<ButtonGroup>
													{status === 'complete' && <Button className="btn btn-primary btn-lg rounded-pill" type="button" onClick={() => history.push('/survey')}>Retake Survey</Button>}
												</ButtonGroup>
											</ButtonToolbar>
										}
									</Card.Body>
								</Card>

							</Col>
							<Col>
								<div>
									<img src={require('../images/assessment-icon-21.jpg')} alt='mySvgImage' />
								</div>
							</Col>
						</Row>

					</Container>
				</div>
			}
		</>
	)
}
