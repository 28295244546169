import React, { useState, useEffect } from 'react'; 
import {  SurveyComponent } from '../components/survey';
import { Container, Alert } from 'react-bootstrap';
import 'survey-react/survey.css'
import { Defaultnav } from '../components/navbar';
import { API_ENDPOINT } from '../utils/endpoint';

export const SurveyPage = () => {
	const [status, setStatus] = useState(null)

	const getStatus = async () => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/survey/status`, {
			headers:
			{
				'Content-type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			method: 'GET',
		}).then((res) => res.json())
			.then(result => setStatus(result.status))
	}


	useEffect(() => {
		getStatus()
	}, [])
	return (
		<>
		<Defaultnav />
		<Container className = "mt-5 mb-5">
			{status === 'complete' && <Alert variant="warning" >
			Completing the survey again will overwrite your existing results!
			</Alert>}
			<SurveyComponent/>
		</Container>
		</>
	)
}

