import React, { Component } from 'react';
import { questionMap } from '../survey/Question map';
import {
	Chart as ChartJS,
	defaults,
	LinearScale,
	CategoryScale,
	RadialLinearScale,
	PointElement,
	BarElement,
	LineElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';
import { Radar, Bar } from 'react-chartjs-2';
import { Button } from 'react-bootstrap';
import { colorMap } from '../survey/ColorMap';
ChartJS.register(
	RadialLinearScale,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend
);


export class RadarChartComponent extends Component {

	constructor(props) {
		super(props);
		this.chartRef = React.createRef();

	}

	render() {

		const createPNG = (e) => {
			let chart = this.chartRef.current;
			let url = chart.toBase64Image()
			var a = document.createElement('a')
			a.href = url
			a.download = " Radar Chart.png"
			a.click()
		}


		const splitKey = (key) => {
			if ((key).includes('_')) {
				return key.split('_')[2]
			} else
				return key
		}

		const labels = Object.keys(this.props.data.response[0]).filter(key => key !== 'name' & key !== 'organization' & key !== 'email').map(key => key)

		const colors = [
			"rgba(22,60,93,.3)",
			"rgba(0,139,174,.3)",
			"rgba(35, 158, 107, .3)",
			"rgba(35, 206, 175, .3)",
			"rgba(255, 178, 54, .3)",
			"rgba(214, 220, 227, .3)",
			"rgba(135, 140, 201, .3)",
			"rgba(226, 117, 117, .3)",
			"rgba(82, 135, 159, .3)",
			"rgba(127, 197, 214, .3)",
			"rgba(142, 206, 182, .3)"
		]

		const filtered = this.props.data.response.filter(d => this.props.selected.includes(d.email))

		const datasets = Object.keys(filtered).map(key => {

			const d = filtered[key]
			return {
				label: d.name,
				data: labels.map(l => d[l]),
				backgroundColor: colors[key],
				borderColor: 'black',
				borderWidth: 1,
			}
		})

		const createToolTip = (alias) => {

			let question = questionMap.get(alias.toLowerCase())
			return question

		}


		const options = {
			maintainAspectRatio: false,
			plugins: {
				tooltip: {
					callbacks: {
						footer: function (item) {
							let tt = createToolTip(item[0].label)
							return tt
						}
					}
				}
			},
			scales: {
				r: {
					min: 0,
					max: 5,
					pointLabels: {
						color: this.props.type === 'questions' ? labels.map(l => colorMap.get(splitKey(l).toLowerCase())) : 'black',
						font: {
							size: 14,


						}
					}
				}
			}

		}


		const chartdata = { labels: labels.map(l => splitKey(l)), datasets: datasets }


		return (
			<div style={{ alignContent: 'center', position: 'relative', height: '70vh', width: '65vw', left: '50%', transform: 'translateX(-50%)' }}>
				<Button className="mt-2 mb-2 btn rounded-pill" variant="alt6" onClick={(e) => createPNG(e)}>Download</Button>
				
				<Radar data={chartdata} options={options} ref={this.chartRef} />
				{this.props.type === 'questions' && <dl>
					<dt class="lead"></dt>
					<dd>Leading the Transformation</dd>

					<dt class="steel"></dt>
					<dd>Aligning Expectations</dd>

					<dt class="honey"></dt>
					<dd>Managing Performance</dd>

					<dt class="iron"></dt>
					<dd>Sustainable Growth</dd>
				</dl>}
			</div>
		)
	}
}


export default class BarChartComponent extends Component {
	constructor(props) {
		super(props);
		this.chartRef = React.createRef();
	}

	render() {

		const createPNG = (e) => {
			let chart = this.chartRef.current;
			let url = chart.toBase64Image()
			var a = document.createElement('a')
			a.href = url
			a.download = "Bar Chart.png"
			a.click()
		}

		const filteredData = this.props.data.response.filter(d => this.props.selected.includes(d.email))

		const colors = [
			"rgba(22,60,93,.9)",
			"rgba(0,139,174,.9)",
			"rgba(35, 158, 107, .9)",
			"rgba(35, 206, 175, .9)",
			"rgba(255, 178, 54, .9)",
			"rgba(214, 220, 227, .9)",
			"rgba(135, 140, 201, .9)",
			"rgba(226, 117, 117, .9)",
			"rgba(82, 135, 159, .9)",
			"rgba(127, 197, 214, .9)",
			"rgba(142, 206, 182, .9)"
		]

		const splitKey = (key) => {
			if ((key).includes('_')) {

				return key.split('_')[2]
			} else
				return key
		}

		const labels = Object.keys(this.props.data.response[0]).filter(key => key !== 'name' & key !== 'organization' & key !== 'email').map(key => key)
		const names = filteredData.map(res => res.name)
		const avgResponse = labels.map(l => {
			let vals = filteredData.map(res => res[l])
			return vals.reduce((a, b) => a + b) / vals.length

		})

		const createToolTip = (alias) => {

			let question = questionMap.get(alias.toLowerCase())
			return question

		}

		const d = {
			labels: labels.map(l => splitKey(l)),
			datasets: [{
				label: names.join(', '),
				data: avgResponse,
				backgroundColor: colors[0],
				borderColor: 'black',
				borderWidth: 1
			}]
		};

		const options = {
			plugins: {
				tooltip: {
					callbacks: {
						footer: function (item) {
							let tt = createToolTip(item[0].label)
							return tt
						}
					}
				}
			},
			maintainAspectRatio: false,
			scales: {
				y: {

					min: 0,
					max: 6
				},
				x: {
					ticks: {
						color: labels.map(l => colorMap.get(splitKey(l).toLowerCase()))
					},
				}
			}
		}

		return (
			<div style={{ alignContent: 'center', marginLeft: '5px', position: 'relative', height: '50vh', width: '50vw', left: '50%', transform: 'translateX(-50%)' }}>

				<Button className="mt-2 mb-2 btn rounded-pill" variant="alt6" onClick={(e) => createPNG(e)}>Download</Button>
				<Bar data={d} options={options} ref={this.chartRef} />
				{this.props.type === 'questions' && <dl>
					<dt class="lead"></dt>
					<dd>Leading the Transformation</dd>

					<dt class="steel"></dt>
					<dd>Aligning Expectations</dd>

					<dt class="honey"></dt>
					<dd>Managing Performance</dd>

					<dt class="iron"></dt>
					<dd>Sustainable Growth</dd>
				</dl>}
			</div>
		)

	}
}

