import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Container, Row, Col, Alert, Card, Button } from 'react-bootstrap';
import { Login } from '../auth/login';
import { Register } from '../auth/register';
import { Defaultnav } from '../components/navbar';
import { API_ENDPOINT } from '../utils/endpoint';
import { StyledCard } from '../components/styledcard';
import { FaPoll } from 'react-icons/fa';


export const Landing = (props) => {

	const [emailExists, setEmailExists] = useState(false)
	const [isUser, setIsUser] = useState(false)
	const [email, setEmail] = useState(null)
	const [invalid, setInvalid] = useState(false)


	const checkEmail = async (e) => {
		e.preventDefault();
		localStorage.setItem("email", email)

		await fetch(`${API_ENDPOINT}/invites/exists/?email=${encodeURIComponent(email)}`, {
			method: 'GET',
			headers: { "Content-Type": "application/json" },
		})
			.then(res => res.json())
			.then(result => {
				if (result) {
					setIsUser(result.active)
					setEmailExists(true)

				} else {
					setInvalid(true)
				}

			})
	}



	return (
		<>
			<Defaultnav />
			<Container className="mt-4 ">
				{!isUser && !emailExists &&
					<Row className="justify-content-center mt-5">
						<Col lg={6} md={6} sm={12} >
							<Card className="shadow">
								<Card.Header className="m-1 border-0 bg-white">
									<h3>Please enter your Email </h3>
									<FaPoll size={60} color='#ffb236'></FaPoll>
								</Card.Header>
								<Card.Body>
									{invalid &&
										<Alert variant={'danger'}>
											That email isn't registered in our system. Check your invitation to be sure you have entered the correct address
										</Alert>}
									<Form onSubmit={checkEmail}>
										<Form.Control
											className="mb-2"
											type="input"
											placeholder="example@email.com"
											onChange={(e) => setEmail(e.target.value)}>
										</Form.Control>
										<Button className="btn rounded-pill" type = 'submit' onClick={checkEmail}>Next</Button>
									</Form>
									
								</Card.Body>
							</Card>
						</Col>
					</Row>}
				{isUser &&
					<Row className="justify-content-center mt-5">
						<Col lg={6} md={6} sm={12} className="mt-4 ml-4 mr-4 ">
							<Card className="shadow mx-auto p-5 smallwidth"> <Login {...props} /> </Card>
						</Col>
					</Row>}
				{!isUser && emailExists &&
					<Row className="justify-content-center mt-5">
						<Col lg={6} md={6} sm={12} className="mt-4">
							<Card className="shadow mx-auto p-5 smallwidth"><Register {...props} /></Card>
						</Col>
					</Row>
				}
			</Container>
		</>
	);
};