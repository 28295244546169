import React from 'react'; 
import { Table, Col, Row, Card } from 'react-bootstrap'; 


export const ResultTable = ( {data, selected  }) => {

    const domains = Object.keys(data[Object.keys(data)[0]][0])

    const dimensions = domains.map(d => data[Object.keys(data)[0]][0][d]).map(d => Object.keys(d)).flat()

    const questions = []

    Object.keys(data).map(key => {
        Object.keys(data[key]).map(newKey => {
           Object.keys((data[key][newKey])).map(newNewKey => {
               Object.keys(data[key][newKey][newNewKey]).map(newNewNewKey => {
                   Object.keys(data[key][newKey][newNewKey][newNewNewKey]).map(finalKey => {
                       questions.push(data[key][newKey][newNewKey][newNewNewKey][finalKey]['question'])
                   })
               })
           })
        })
    })

	return (
        <div className="table-responsive" style={{width: '100%'}}>
		<Table className = "mx-auto w-auto" >
			<thead>
            <tr>
            {domains.map(domain => {
                return (
                
					<th rowspan="1" colspan="3">
						{domain}
					</th>
				
                )
            })
        }
        </tr>
        <tr>
            <th>
        
            </th>
            {dimensions.map(d => {
            return (
            <th rowspan="1" colspan="1" >
                {d}
            </th>

                )
            }
            )}
        </tr>
        <tr>
			<th>Name</th>
            {Array.from(new Set(questions)).map(q => {
            return (<th>{q}</th>)
            })}
            
        </tr>
    </thead>
    <tbody></tbody>
		</Table>
        </div>
	)
}