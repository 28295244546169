import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Navbar, Nav, NavLink, Container, Row, Col, Alert, Button, Card } from 'react-bootstrap';
import { FaDatabase, FaSearch, FaSearchengin, FaSearchPlus, FaUserCircle } from 'react-icons/fa';
import auth from '../auth/auth';
import { API_ENDPOINT } from '../utils/endpoint';

export const Defaultnav = (props) => {
	// History hook
	const history = useHistory();
	const [show, setShow] = useState(true);
	const [status, setStatus] = useState(null)

	const getStatus = async () => {
		const token = localStorage.getItem('token')
		await fetch(`${API_ENDPOINT}/survey/status`, {
			headers:
			{
				'Content-type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			method: 'GET',
		}).then((res) => res.json())
			.then(result => setStatus(result.status))
	}

	// User information hook
	const [user, setUser] = useState({
		"id": "",
		"email": "",
		"is_active": true,
		"is_superuser": false,
		"firstName": "",
		"lastName": ""
	});

	const permissions = localStorage.getItem('permissions')

	useEffect(() => {
		const fetchData = async () => {
			if (auth.isAuthenticated()) {
				const result = await auth.getUser();
				setUser(result);
			} else {
				setUser(null)
			};
		};
		fetchData();
		getStatus();
		// eslint-disable-next-line
	}, []);

	// Function to call logout
	const callLogout = async () => {
		auth.logout(() => {
			history.push('/');
		});
	};

	const date = new Date().toDateString()

	return (
		<>
			<Navbar className="shadow-sm" bg="white" variant="light">
				<Container fluid>
					<Navbar.Brand href="/" >
						<img
							alt=""
							src={require("../images/Marmon_Foodservice_Technologies_Logo_-_Color.png")}
							width="110"
							height="50"
							className="d-inline-block align-top"
						/>
						<Navbar.Brand className="pl-3 ml-1 pr-5" >  </Navbar.Brand>
					</Navbar.Brand>
					{user &&
						<>
							<Navbar.Collapse className="justify-content-start">
								<Nav.Item className="align-items-left ml-auto">
									<Nav.Link href="/" >Home</Nav.Link>
								</Nav.Item>
								{permissions === 'admin' && <Nav.Item className="align-items-left ml-auto">
									<Nav.Link href="/invite">Invite Participants</Nav.Link>
								</Nav.Item>
								}
								<Nav.Item>
									<Nav.Link href="/survey">Survey</Nav.Link>
								</Nav.Item>
								{(status === 'complete' || permissions === 'admin') && <Nav.Item className="align-items-left ml-auto">
									<Nav.Link href="/dashboard">Dashboard</Nav.Link>
								</Nav.Item>}
							</Navbar.Collapse>
							<div>
								<label className="ml-4 mr-4" style={{ color: '#fff' }}><FaUserCircle size={21} /></label>
								<label className="ml-4 mr-4 p-2 ">{user.email}</label>
								<Button className="ml-3 rounded-pill"
									style={{ background: 'transparent', color: '#21049f', border: '1px solid white' }}
									onClick={callLogout}>Log Out</Button>
							</div>
						</>
					}
				</Container>
			</Navbar>
		</>)
}