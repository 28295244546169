import React from 'react'; 
import { BrowserRouter } from 'react-router-dom'; 
import './index.scss'
//import 'bootstrap/dist/css/bootstrap.min.css';
import { ForgotPassword } from './auth/forgotpassword';
import { ResetPassword } from './auth/resetpassword';
import { Route } from 'react-router-dom';
import './App.css';
import { Landing } from './pages/Landing';
import { HomePage } from './pages/Home';
import  { ProtectedRoute, AdminRoute } from './auth/protected-route'
import { SurveyPage } from './pages/Survey';
import { DashboardPage } from './pages/Dashboard';
import { InvitePage } from './pages/Invite'
function App() {
  return (
    // Router Code
    <BrowserRouter>
      <div  className="App">
         <ProtectedRoute 
            path='/'
            exact 
            strict
            component = {HomePage}
          /> 
          <ProtectedRoute 
            path='/survey'
            exact 
            strict
            component = {SurveyPage}
          /> 
          <AdminRoute 
            path="/invite" 
            exact
            strict 
            component = {InvitePage}
          />
          <ProtectedRoute 
            path='/dashboard'
            exact 
            strict
            component = {DashboardPage}
          /> 
          <Route 
          path = "/login" 
          component={Landing}
          />
          <Route 
            path='/forgotpassword' 
            exact
            strict 
            component = {ForgotPassword}
            />
          <Route 
            path='/resetpassword' 
            exact
            strict 
            component = {ResetPassword}
            />
       </div>
    </BrowserRouter>
  );
};
export default App;

