import React, { useState } from 'react';
import { Defaultnav } from '../components/navbar';
import { Container, Col, Row, Card, Form, Button, Alert } from 'react-bootstrap';
import { API_ENDPOINT } from '../utils/endpoint';

export const InvitePage = () => {

	const defaultmessage = "You've been invited to join the Leadership Questionnaire. To join, please follow the link and create an account."

	const [emails, setEmails] = useState([])
	const [message, setMessage] = useState(defaultmessage)
	const [sentStatus, setSentStatus] = useState(false) 
	const [groupName, setGroupName] = useState(null)
	const [error, setError] = useState(null)
	const [submitted, setSubmitted] = useState(false)

	const sendEmails  = async () => {
		await fetch(`${API_ENDPOINT}/email`, {
			headers: { 'Content-type': 'application/json' },
			method: 'POST',
			body: JSON.stringify({"email": emails, "body": {"message": message}})
		}).then((res) => res.json())
			.then(result => { setSentStatus(true) 
				setSubmitted(false)
				alert("Email(s) sent successfully") })
	}

	const addInvitees  = async () => {
		await fetch(`${API_ENDPOINT}/invites/batch`, {
			headers: { 'Content-type': 'application/json' },
			method: 'POST',
			body: JSON.stringify({"email": emails, "active": false, "organization": groupName})
		}).then((res) => res.json())
			.then(result => { sentStatus === true && setEmails([]) })
	}


	const inValid = emails.length === 0|| groupName === null || groupName === undefined 

	const resetState = () => {
		setEmails([])
		setGroupName(null) 
		setError(false) 
	}


	const onSubmit = (e) => {
		e.preventDefault();
		if (!inValid) {
			setSubmitted(true)
			sendEmails() 
			addInvitees()
			resetState()
		} else {
			setError(true)
		}
	}

	return (
		<>
			<Defaultnav />
			<Container className = "align-items-start text-align-left " >
				<Row className = "mt-4 mb-4">
					<Col>
						<h2> Invite Users </h2>
					</Col>
					{submitted && <Alert variant='success' > Your message is being processed</Alert>}
				</Row>
				<Row className = "align-items-start text-align-left">
					<Col>
						<Card className="shadow-sm text-align-left ">


						<Card.Body className = "leftAligned">
							<p>Enter the email of individuals that you would like to invite to take the survey. <strong> Be sure that you separate
								each email with a comma</strong>. Individuals will be sent an email with your message below as well as a link to the survey.</p>
								<p>If you add an individual who has already been invited, their group will be updated (if changed) and a new email invite will be sent. </p>

							<Alert variant='primary'>Users will be asked to create a login in order to access the application. Individuals who were not invited will not be able to create an account</Alert>
							<Form className = "align-items-left" onSubmit={onSubmit}>
								<Form.Group>
									<Form.Label className = "required-field">
										Group Name
									</Form.Label>
									<Form.Control type = "input" placeholder="Group A" value = {groupName !== null ? groupName: "Group A"} onChange={(e) => setGroupName(e.target.value)} required/> 	
									<Form.Label className = "required-field">
										Emails
									</Form.Label>
									<Form.Control type="input" placeholder="email@gmail.com, email@hotmail.com" value = {emails.length !== 0 ? emails.join(','):"email@gmail.com, email@hotmail.com"  } onChange = {(e) => setEmails(e.target.value.split(','))} required/>
									<Form.Text className="text-muted align-text-left">
										Be sure to seperate each email with a comma
									</Form.Text>
								</Form.Group>
								<Form.Group>
									<Form.Label> Message </Form.Label>
									<Form.Control as="textarea" rows={8} placeholder={defaultmessage} onChange={(e) => setMessage(e.target.value)}/>
								</Form.Group>			
								<Button hidden type="submit"></Button>					
							</Form>
						</Card.Body>
						<Card.Footer className = "bg-white">
						<Button className = "m-4 rounded-pill" variant="primary" type="submit" onClick={onSubmit}>
									Submit
						</Button>
						{error && <Alert variant = "danger"> Be sure to fill out all required fields!</Alert>}
						</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>

		</>
	)
}