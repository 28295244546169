import React, { useEffect, useState } from 'react';
import BarChartComponent, { RadarChart, BarChart, RadarChartComponent } from '../components/chart';
import { Container, Col, Row, Card, Button, ButtonGroup, Alert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Defaultnav } from '../components/navbar';
import { API_ENDPOINT } from '../utils/endpoint';
import { ButtonToolbar } from 'react-bootstrap';
import Select from 'react-select';
import { ResultTable } from '../components/resulttable';
import auth from '../auth/auth';
import _ from 'lodash'; 

export const DashboardPage = () => {

	const [result, setResult] = useState(null)
	const [selectedRows, setSelectedRows] = useState([])
	const [toggleCleared, setToggleCleared] = useState(false)
	const [data, setData] = useState(null)
	const [chartType, setChartType] = useState('radar')
	const [currentToggle, setCurrentToggle] = useState('domains')
	const [dimensionResult, setDimensionResult] = useState(null)
	const [user, setUser] = useState({
		"id": "",
		"email": "",
		"is_active": true,
		"is_superuser": false,
		"firstName": "",
		"lastName": ""
	});
	const [fullResult, setFullResult] = useState(null)
	const [group, setGroup] = useState(null)


	const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);
	

	const download = (blob, filename) => {
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		// the filename you want
		a.download = "table.csv";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}
	const fetchData = async () => {
		await fetch(`${API_ENDPOINT}/survey/scores`, {
			headers: { 'Content-type': 'application/json' },
			type: 'GET'
		}).then((res) => res.json())
			.then(result => { setResult(result) 
				})
	}

	const fetchDimensionData = async () => {
		await fetch(`${API_ENDPOINT}/survey/scores/dimensions`, {
			headers: { 'Content-type': 'application/json' },
			type: 'GET'
		}).then((res) => res.json())
			.then(result => { setDimensionResult(result) })
	}

	const fetchFullData = async () => {
		await fetch(`${API_ENDPOINT}/survey/scores/questions`, {
			headers: { 'Content-type': 'application/json' },
			type: 'GET'
		}).then((res) => res.json())
			.then(result => { setFullResult(result) })
	}

	const fetchCSV = async () => {
		await fetch(`${API_ENDPOINT}/csv`, {
			method: 'GET'
		}).then((res) => res.blob())
			.then(blob => download(blob))
	}

	useEffect(() => {
		fetchData()
		fetchDimensionData()
		fetchFullData()

	}, [])

	const getDownload = (e) => {
		e.preventDefault();
		fetchCSV()
	}

	useEffect(() => {
		const fetchData = async () => {
			if (auth.isAuthenticated()) {
				const result = await auth.getUser();
				setUser(result);
			} else {
				setUser(null)
			};
		};
		fetchData();
		// eslint-disable-next-line
	}, []);

	const columns = [
		{
			name: 'Name',
			selector: row => row.name ,
		},
		{
			name: 'Group',
			selector: row => row.group
		}
	];


	const toggleChartType = (e) => {
		setChartType(e.value)
	}

	const getData = () => {
		let selectedData = currentToggle === 'domains' ? result : (currentToggle === 'dimensions' ? dimensionResult : fullResult)
		return selectedData
	}

	const getNames = () => {
		return selectedRows ? selectedRows.map(r => r.id) : [user.email]

	}


	const filterDataMap = (data, group) => {
		const res = group ? _(data).filter(r => r.organization === group.value).value() : data 
		const response = Object.keys(res).map(key => {
			return {
				id: res[key].email,
				name: res[key].name,
				group: res[key].organization,
			}
		})
		return response
	}

	const paginationComponentOptions = {
		noRowsPerPage: true, 

	}



	return (
		<>
			<Defaultnav />
			{result && <Container fluid>
				<Row>
					<Col className="mt-2">
						<h2> Survey Dashboard </h2>
					</Col>
				</Row>
				<Row  noGutters={false}>
					<Col className="xs-2 sm-2 md-2 lg-2 mt-5 align-items-center shadow-sm offset-lg-1" style = {{maxWidth: '500px'}}>
					<Card className="border-0 ">
						<Card.Header className = "bg-white border-0">
							Select Group
							</Card.Header>
							<Select 
								onChange={setGroup}
								options={Array.from(new Set(Object.keys(result.response).map(key => result.response[key].organization))).map(org => { 
									return {label: org, value: org}})}
								theme={(theme) => ({
										...theme,
										borderRadius: 30,

									})}
								isClearable={() => setGroup(null)}	
								isSearchable/>
							<Card.Header className="bg-white">
								Select Respondents
							</Card.Header>
							<Card.Body>
								<DataTable 
									columns={columns} 
									data={filterDataMap(result.response, group)}
									onSelectedRowsChange={handleRowSelected}
									clearSelectedRows={toggleCleared}
									//selectableRowSelected={rowSelectCriteria}
									paginationComponentOptions={paginationComponentOptions}
									selectableRows
									pagination
									paginationPerPage={5}
									paginationRowsPerPageOptions = {[5, 10]} />
							</Card.Body>
						</Card>
						<Card className="border-0 ml-4">
							<Card.Header className="bg-white">
								Score Selection
							</Card.Header>
							<Card.Body style={{overflow: 'visible'}}>
								<Select
									defaultValue={{ label: 'Domains', value: 'domains' }}
									options={[{ label: 'Dimension', value: 'dimensions' }, 
									{ label: 'Domains', value: 'domains' }, 
									{ label: 'Questions', value: 'questions' }]}
									onChange={(e, o) => setCurrentToggle(e.value)}
									theme={(theme) => ({
										...theme,
										borderRadius: 30,
									})} />
							</Card.Body>
						</Card>
						<Card className="border-0">
							<Card.Header className="bg-white">
								Chart Type
							</Card.Header>
							<Card.Body style={{overflow: 'auto'}}>
								<Select
									defaultValue={{ label: 'Radar Chart', value: 'radar' }}
									options={[{ label: 'Bar Chart', value: 'bar' }, 
									{ label: 'Radar Chart', value: 'radar' }]}
									onChange={(e, o) => toggleChartType(e)}
									theme={(theme) => ({
										...theme,
										borderRadius: 30,
									})} />
								<Button className="mt-5 rounded-pill" onClick={getDownload}>
									Download Table
								</Button>
							</Card.Body>
						</Card>
					</Col>
					<Col className="mt-5 mb-5 align-items-center" xs={4} sm={6} md={6} lg={6}>
						{selectedRows.length > 0 &&
							<Card className="border-0 " >
								{chartType === 'radar' ?
									<RadarChartComponent data={getData()} selected={getNames()} type={currentToggle}/> : (chartType === 'bar' ?
										<BarChartComponent data={getData()} selected={getNames()} type={currentToggle} /> : 
											<ResultTable data={fullResult} selected={getNames()} />)}
							</Card>
						}
						{selectedRows.length === 0 &&
							<Alert >Please select respondents to view chart</Alert>}
					</Col>
				</Row>
			</Container>}
			<footer><br></br></footer>
		</>
	)
}