import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Form, Button, Alert, Row, Col } from 'react-bootstrap';
import auth from './auth';
import { API_ENDPOINT } from '../utils/endpoint';
export const Register = () => {
  // History hook
  const history = useHistory();

  // User information hook
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(localStorage.getItem("email")|| '');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [org, setOrg] = useState('')

  // Function to call submit
  const callSubmit = async (e) => {
    // Prevents page reload on wrongs creds
    e.preventDefault();
    setError('');

    const activateUser = async () => {
      await fetch(`${API_ENDPOINT}/invites/activate`, {
        headers: { 'Content-type': 'application/json' },
        method: 'PATCH',
        body: JSON.stringify({'email': email, "active": true, 'first_name': firstName, 'last_name': lastName})
      }).then((res) => res.json())
    }
    

    // need to first fetch and see if email exists, if it does, then proceed, otherwise show error 
    
    try {
      const data = await auth.register(firstName, lastName, email, password, passwordConfirmation);
      // Executes only when there are no 400 and 500 errors, else they are thrown as errors
      // Callbacks can be added here
      if (data) {
        await auth.login(email, password);
        activateUser()
        alert('Registration Successful!');
        history.push('/');
      }
    }
    catch (err) {
      if (err instanceof Error) {
        // Handle errors thrown from frontend
        setError(err.message);
      } 
      else {
        // Handle errors thrown from backend
        if (err === 'REGISTER_USER_ALREADY_EXISTS') {
          setError('Email ID is already registered. Please use your credentials to login.');
        }
        else {
          setError('Error occured in the API.');
        }
      }
    }
  };

  return (
    <>
      <h2>Register</h2>
      <Form onSubmit={callSubmit}>
        <Row className = "mb-2">
          <Col>
          <Form.Group controlId="formRegisterFirstname">
         
          <Form.Control type="text" placeholder="Enter first name" value={firstName} onChange={(f) => setFirstName(f.currentTarget.value)} />
        </Form.Group>
          </Col>
          <Col>
          <Form.Group controlId="formRegisterLastname">
          
          <Form.Control type="text" placeholder="Enter last name" value={lastName} onChange={(l) => setLastName(l.currentTarget.value)} />
        </Form.Group>
          </Col>
        </Row>
        
       <Row>
         <Col>
        <Form.Group controlId="formRegisterEmail" className = "mb-2">
         
          <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
        </Form.Group>
        </Col>
        {/* <Col>
        <Form.Group controlId="formRegisterOrganization" className = "mb-2">
         
         <Form.Control type="text" placeholder="Enter organization" value={org} onChange={(e) => setOrg(e.currentTarget.value)} />
       </Form.Group>
        </Col> */}
        </Row>
        <Form.Group controlId="formRegisterPassword" className = "mb-2">
        
          <Form.Control type="password" placeholder="Enter password" value={password} onChange={(p) => setPassword(p.currentTarget.value)}/>
        </Form.Group>
        <Form.Group controlId="formRegisterPasswordConfirmation" className = "mb-2">
         
          <Form.Control type="password" placeholder="Confirm password" value={passwordConfirmation} onChange={(p) => setPasswordConfirmation(p.currentTarget.value)}/>
        </Form.Group>
        <Alert variant='danger' style={ error!=='' ? {display:"block"} : {display:"none"}}>
          {error}
        </Alert>
        <Button className = "btn rounded-pill" variant="primary" type="submit" block>
          Register
        </Button>
      </Form>
    </>
  );
};
