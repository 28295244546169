export const questionMap = new Map(
    [
        ["connected", "I feel connected to the MFT Purpose."],
        ["focused", "'MFT's' Vision is focused and achievable."],
        ["align", "'MFT's' Vision is aligned to our global organizational goals."],
        ["clear", "The MFT strategy is clear and well communicated."],
        ["understood", "The MFT strategy is easily understood throughout the organization."],
        ["role", "I understand how my role fits into the overall strategy."],
        ["swot", "MFT is realistic about our strengths, weaknesses, opportunities, and threats."],
        ["equip", "MFT is well equipped to achieve our goals."],
        ["data", "The MFT strategy is built on data, analysis, and fact."],
        ["achieve", "I beleive we will achieve our goal."],
        ["purpose", "I beleive in the Purpose, Vision, and Strategy."],
        ["dedicate", "I am willing to dedicate the time and effort to adopt the new mindset required to deliver sustainable growth."],
        ["motivated", "I am motivated to develop new habits to ensure we achieve our goals."],
        ["inspire", "MFT communications engage and inspire me."],
        ["culture", "Communications on culture (Purpose/Vision/Strategy) stand out from the general day-to-day communications."],
        ["connect", "Communications connect me personally to our Purpose, Vision, and Strategy."],
        ["aligned", "Communications are aligned."],
        ["priority", "I have identified the priority leadership activities, required by me, to achieve the MFT goals."],
        ["factors", "Critical Success Factors are clear and prioritized."],
        ["contributes", "I know how my role contributes and adds to the greater objectives of MFT."],
        ["capabilities", "I have identified the core capabilities required for me and my area of responsibility to achieve our goals."],
        ["learning", "I am responsible for my own learning and development."],
        ["processes", "I have identified the processes, tools, and technology required for success."],
        ["reviews", "I perform regular reviews of processes and practices to foster a growth mindset and continuous improvement."],
        ["challenge", "I am motivated to challenge the status quo on a daily basis."],
        ["visible", "My leader is visible to me and across the business."],
        ["team", "I am a visible leader to my team and across the business."],
        ["perform", "Under-perfomance is identified and dealt with fairly."],
        ["review", "I regularly review my team's performance and progress against expectations."],
        ["responsibility", "I take personal responsibility for the results of my team."],
        ["identify", "I have identified areas of improvement in my area of responsibility."],
        ["implement", "I regularly implement improvements or new processes in my area of responsibility to drive continuous improvement."]
    ]
)